<div *ngIf="movie" class="competition-{{ movie.friendlyUrl }}">
  <ng-container *ngIf="competition && !competitionPlayed">
    <div
      class="container"
      [style.background-image]="'url(' + background + ')'"
      [style.background-position]="'center'"
      [style.background-size]="'cover'"
    >
      <div class="competition-wrapper page-content" [ngClass]="{ 'has-logo-competition': movieLogo }">
        <ng-container [ngSwitch]="activeSection">
          <ng-template *ngSwitchCase="'competition'" [ngTemplateOutlet]="competitionTemplate"></ng-template>
          <ng-template *ngSwitchCase="'rules'" [ngTemplateOutlet]="rulesTemplate"></ng-template>
          <ng-template *ngSwitchCase="'terms'" [ngTemplateOutlet]="termsTemplate"></ng-template>
          <ng-template *ngSwitchCase="'thankYouScreen'" [ngTemplateOutlet]="thankYouTemplate"></ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="competitionPlayed">
    <span
      class="container competitionPlayed-span"
      [style.background-image]="'url(' + background + ')'"
      [style.background-position]="'center'"
      [style.background-size]="'cover'"
    ></span>
    <div class="something-wrong-wrapper">
      <div class="something-wrong-section">
        <div class="thankyou-content subtitle">
          {{ 'competition.competitionPlayed' | translate }}

          <ng-template [ngTemplateOutlet]="sharedTemplate"></ng-template>
        </div>

        <div class="back-button-container">
          <a class="back-button" (click)="goBack()">
            <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
            {{ 'competition.back' | translate }}
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #competitionTemplate>
  <div *ngIf="movieLogo && movie.logoUrl" class="competition-header">
    <a [routerLink]="'/movie/' + movie.friendlyUrl">
      <img [src]="movie.logoUrl" [alt]="movie?.title + ' competition image'" class="competition-movie-logo" />
    </a>
    <div class="competition-release-message">{{ movie.releaseMessage }}</div>
  </div>
  <div class="content-wrapper">
    <div class="content-questions">
      <div class="competition-title">
        {{ competition.title }}
      </div>

      <div class="competition-questions" [ngClass]="{ hidden: !visible }">
        <ush-question
          [attr.disabled]="allQuestionsAnswered"
          [question]="question"
          [movie]="movie"
          [competition]="competition"
          (answerPicked)="answerPicked($event)"
        >
        </ush-question>
        <div class="form-wrapper" [ngClass]="{ hidden: !allQuestionsAnswered }">
          <ush-form-subscription
            *ngIf="allQuestionsAnswered"
            [formMessage]="'competition.form.header' | translate"
            [buttonText]="'competition.form.submit' | translate"
            [consumerLead]="consumerLead"
            (formSubmitted)="formSubmitted()"
          >
          </ush-form-subscription>
        </div>
      </div>
    </div>

    <div class="content-rules">
      <a (click)="onTermsClicked()">{{ 'competition.section.terms' | translate }}</a>
      <!-- <a (click)="activeSection = 'rules'">{{ 'competition.section.rules' | translate }}</a> -->
    </div>

    <div class="back-button-container">
      <a class="back-button" [routerLink]="customBackButtonUrl || '/movie/' + movie?.friendlyUrl">
        <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
        {{ customBackButtonTrMsg || 'competition.back' | translate }}
      </a>
    </div>
  </div>

  <ng-container [ngTemplateOutlet]="customFooterTemplate"></ng-container>
</ng-template>

<ng-template #termsTemplate>
  <div class="terms-content" [innerHTML]="competition.terms | safe: 'html'" routeTransformer>
    {{ competition.terms }}
  </div>

  <div class="back-button-container">
    <a class="back-button" (click)="activeSection = 'competition'">
      <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
      {{ 'competition.back' | translate }}
    </a>
  </div>
</ng-template>

<ng-template #rulesTemplate>
  <div class="rules-content">some fancy rules text here...</div>

  <div class="back-button-container">
    <a class="back-button" (click)="activeSection = 'competition'">
      <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
      {{ 'competition.back' | translate }}
    </a>
  </div>
</ng-template>

<ng-template #thankYouTemplate>
  <div class="thankyou-content title">
    {{ competition.thankyouMessage }}

    <ng-template [ngTemplateOutlet]="sharedTemplate"></ng-template>
  </div>

  <div class="back-button-container">
    <a class="back-button" (click)="goBack()">
      <fa-icon [icon]="faLongArrowAltLeft"></fa-icon>
      {{ 'competition.back' | translate }}
    </a>
  </div>
</ng-template>

<ng-template #sharedTemplate>
  <ush-movie-tell-friends
    [movie]="movie"
    [message]="'competition.share.message'"
    [mailSubject]="'competition.share.subject'"
    [campaign]="'Sharing-Competition-%movieUrl%'"
    [trackingCategory]="'Competitions'"
    [trackingExtraProps]="{
      movie: movie.title,
      idMovie: movie.id,
      competitionTitle: competition.title,
      idCompetition: competition.id
    }"
  ></ush-movie-tell-friends>
</ng-template>
