import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UsheruApi } from '@usheru-hff/shared/data-access-backend';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Competition } from '../entities/competition.model';
import { ENVIRONMENT, Environment } from '@environment';

const COMPETITIONS_KEY = 'competitions-submitted';
const COMPETITIONS_KEY_SEPARATOR = ',';

@Injectable({
  providedIn: 'root'
})
export class CompetitionsService {
  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    private usheruApi: UsheruApi,
    private http: HttpClient
  ) {}

  public getCompetition(movieId: string): Observable<Competition> {
    // Just try to get competition data if competition is enablen in environment
    if (this.env.competitions) {
      const competitionKeys = this.getCompetitionKeys();

      if (!competitionKeys.includes(movieId)) {
        return this.getCompetitionApi(movieId);
      } else {
        return this.getCompetitionApi(movieId).pipe(
          map(c => {
            if (!c) return c;
            c.played = true;
            return c;
          })
        );
      }
    }
    return of();
  }

  public competitionSubmitted(movieId: string): void {
    const competitionKeys = this.getCompetitionKeys();

    competitionKeys.push(movieId);

    localStorage.setItem(COMPETITIONS_KEY, competitionKeys.join(COMPETITIONS_KEY_SEPARATOR));
  }

  public isCompetitionSubmitted(movieId: string): boolean {
    const competitionKeys = this.getCompetitionKeys();
    return competitionKeys.includes(movieId + '');
  }

  private getCompetitionKeys(): string[] {
    // todo: check if this is the best way to get the competition keys
    // const competitionKeysString = localStorage.getItem(COMPETITIONS_KEY);
    return [];
    // return competitionKeysString ? competitionKeysString.split(COMPETITIONS_KEY_SEPARATOR) : [];
  }

  // competitions
  getCompetitionApi(movieId: string): Observable<Competition> {
    return this.http.get<Competition>(`${this.env.api?.url}/competitions/${movieId}`, {
      headers: this.usheruApi.getStandardHeader()
    });
  }
}
