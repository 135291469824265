import { Routes } from '@angular/router';
import { CompetitionComponent } from './competition.component';

export const COMPETITIONS_ROUTES: Routes = [
  {
    path: ':movieId',
    component: CompetitionComponent,
    data: {
      type: 'competitions',
      animation: 'Join'
    }
  }
];
